import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/Auth';
import {useCardshop} from '../contexts/Cardshop';
import { Shop} from 'react-bootstrap-icons';

const Navbar=()=> {
  const card= useCardshop()

  const auth= useAuth()
  return (
    <div className='w-full'>
    <nav className="navbar navbar-expand-lg navbar-light bg-light align-items-center">
      <div className="container">
      <Link to="/">  <a className="navbar-brand" href="/"><img src='/image/logo-tigmat.png' className='w-8'/></a> </Link>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <ul className="offset-md-8 navbar-nav mr-auto align-items-center">
  
  
    </ul>
  {!Object.keys(auth?.user).length>0 ?(
  <div className="collapse navbar-collapse align-items-center" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto align-items-center">
    
      
      <li className="nav-item align-items-center">
      <Link to="/Signin">  <button className="btn btn-primary btn-block">Sign-Up</button></Link>
      </li>
      <li className="mx-5 nav-item align-items-center">
      
         <Link to="/login"> <button className="btn btn-primary btn-block ">Login</button> </Link>
    
   
      </li>
     

    </ul>
    </div>
        ):auth?.user?.role==="user" ?(<ul className=" navbar-nav mr-auto align-items-center">
        <li className="mx-5 nav-item align-items-center ">
      
      <Link to="/Profile"> <a className="btn btn-primary btn-block ">profile</a> </Link>
 

   </li>
   <li className="nav-item align-items-center">
 
      <Link to="/Store"> <a className="btn btn-primary d-flex gap-2 position-relative align-items-center "><Shop/>Cart {card.cart.length>0?(<span className='position-absolute notif fs-8'>{card.cart.length}</span>):''}</a></Link>
      </li>
  
   </ul>):(
   <ul className=" navbar-nav mr-auto align-items-center">
   <li className="mx-5 nav-item align-items-center ">
 
 <Link to="/Profile"> <a className="btn btn-primary btn-block ">profile</a> </Link>


</li>


</ul>)}
          
  </div>
</nav>
</div>
  )
}

export default Navbar;
