import {React,createContext,useState,useContext} from 'react';

const CardshopContext = createContext()

export const CardshopProvider = ({children})=>{
    const [cart, Setcart] = useState([])
    const [total, SetTotal] = useState([])
    const AddtoCarts=(name, price,url)=>{
        const currentProductAdd= cart.find((product)=> product.name === name)
        if(currentProductAdd){
        const cartFilteredCurrentProduct = cart.filter(
             (product)=> product.name !== name
        )
        Setcart([...cartFilteredCurrentProduct,{name,price,qty:currentProductAdd.qty+1,total:Math.round(((currentProductAdd.qty+1)*currentProductAdd.price) * 100) / 100 ,image:url}])
        }
        else{
          Setcart([...cart,{name,price,qty:1,total:price*1,image:url}])
        }
        
  
     
      }
      const RemoveCard=()=>{
        Setcart([])
    } 
    const removeProduct=(product)=>{
       const currentProductAdd=cart.find((poductItem)=>poductItem.name === product.name)
       if (currentProductAdd.qty===1){
        
        Setcart([...cart.filter((productItem)=>productItem.name !== product.name )])
       }
       else{
        Setcart([...cart.map((productItem)=>
          productItem.name === product.name?{...currentProductAdd,qty:currentProductAdd.qty-1,total:Math.round(((currentProductAdd.qty-1)*currentProductAdd.price) * 100) / 100 }:productItem
        )])
       }
    }
    const AddOneProduct=(product)=>{
      const currentProductAdd=cart.find((poductItem)=>poductItem.name === product.name)
      if (currentProductAdd){
        Setcart([...cart.map((productItem)=>
          productItem.name === product.name?{...currentProductAdd,qty:currentProductAdd.qty+1,total:Math.round(((currentProductAdd.qty+1)*currentProductAdd.price) * 100) / 100 }:productItem
        )])
      }
      
   }
      return (
        <CardshopContext.Provider value={{cart,AddtoCarts,RemoveCard,removeProduct,AddOneProduct}}>
            {children}
        </CardshopContext.Provider>
      )
     
}

export  const useCardshop = () => {
    return (useContext(CardshopContext))
 }
 