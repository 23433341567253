import React from 'react'
export default function ConnexionPage() {
  return (
    <div className='container'>
        <h1>PROJET React JS</h1>
        <div className="row justify-content-center">
            <p className="fs-4">Connecter vous ou Inscrivez-vous gratuitement</p>
       
        </div>
    </div>
  )
}
