import {React,useState} from 'react'
import Data from '../../data/data.json'
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import "/node_modules/primeflex/primeflex.css"
import "./Laptop.css"
import { Rating } from 'primereact/rating';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import {useCardshop} from '../../contexts/Cardshop'
import { useAuth } from '../../contexts/Auth';
import userEvent from '@testing-library/user-event';
import { useEffect } from 'react';
import ButtonAddProduct from '../../compoment/ButtonAddProduct';
import { useDataContext } from '../../contexts/DataContext';

export default function Laptop() {

  const auth = useAuth()
  const cards= useCardshop()
  const Dataproduct= useDataContext().dataJson[0]

  const [layout, setLayout] = useState('grid');
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortField, setSortField] = useState(null);
  const sortOptions = [
    {label: 'Price High to Low', value: '!Price'},
    {label: 'Price Low to High', value: 'Price'},
];
useEffect(()=>{
    auth?.user.role==="admin"? setLayout('list'):setLayout('grid')
},[layout])

  const onSortChange = (event) => {
    const value = event.value;

    if (value.indexOf('!') === 0) {
        setSortOrder(-1);
        setSortField(value.substring(1, value.length));
        setSortKey(value);
    }
    else {
        setSortOrder(1);
        setSortField(value);
        setSortKey(value);
    }
}

  const renderListItem = (data) => {
    return (
        <div className="col-12">
            <div className="product-list-item">
                {console.log(cards.cart+"d")}
                <img src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
                <div className="product-list-detail">
                    <div className="product-name">{data.name}</div>
                    <div className="product-description">{}</div>
                    <Rating value={data.rating} readOnly cancel={false}></Rating>
                    <i className="pi pi-tag product-category-icon"></i><span className="product-category">{data.category}</span>
                </div>
                <div className="product-list-action">
                    <span className="product-price">${data.Price}</span>
                    <Link to={`/Product/${0}/${data.id}`}> <Button icon="pi pi-shopping-cart" label="Show details"></Button> </Link >  

                </div>
            </div>
        </div>
    );
  }

  const renderGridItem = (data) => {
    return (
        <div className="col-4 md:col-4 mh-100">
            <div className="product-grid-item card">
                <div className="product-grid-item-top">
                    <div>
                        <i className="pi pi-tag product-category-icon"></i>
                        <span className="product-category">{data.category}</span>
                    </div>
                </div>
                <div className="product-grid-item-content">
                <img src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
                    <div className="product-name">{data.name}</div>
                    <div className="product-description">{data.description}</div>
              
                </div>
                <div className="product-grid-item-bottom justify-content-center mb-3 mt-2">
                    <span className="product-price ">${data.Price}</span>
                     
                </div>
              
                <div className='d-flex flex-row justify-content-evenly'>
                {auth?.user.role==="admin"?( 
                    ""):(
                        <><Button icon="pi pi-shopping-cart" label="Add TO Card" onClick={()=>cards.AddtoCarts(data.name,data.Price,data.image)} ></Button> 
                        <Link to={`/Product/${2}/${data.id}`}>        <Button icon="pi pi-shopping-cart" label="Show" disabled={data.inventoryStatus === 'OUTOFSTOCK'}></Button> </Link></> 
                       
                    )}

               
            </div>
            </div>
        </div>
    );
}
const itemTemplate = (Dataproduct, layout) => {
  if (!Dataproduct) {
      return;
  }

  if (layout === 'list')
      return renderListItem(Dataproduct);
  else if (layout === 'grid')
      return renderGridItem(Dataproduct);
}





  return (
    
    <div className="Flexbox-Menu">
      
   
    <div className="content-laptop">
    <div className='container tex-wrap '>
    <div className="dataview-demo">
      <div className="card">
      <ButtonAddProduct data={Dataproduct} index={0}/>

          <DataView value={Dataproduct} layout={layout} 
                  itemTemplate={itemTemplate} paginator  rows={9}
                  sortOrder={sortOrder} sortField={sortField}
                />
      </div>
  </div>
    {  //<DataView value={"Laptopdata"} layout={"layout"} itemTemplate={itemTemplate}></DataView> 
 }
 
     </div>
    </div>
    </div>
  )
}
