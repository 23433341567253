import React from 'react'
import "./Home.css"
import { reactLocalStorage } from 'reactjs-localstorage'
export default function Home() {
  return (
    <>
    <h1>welcome</h1>
    <div className="Flexbox-Menu">
 
    <div className="content">

    </div>
    </div>
    </>
  )
}
