import { type } from '@testing-library/user-event/dist/type';
import { React,useState, useEffect} from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {reactLocalStorage} from 'reactjs-localstorage';
import  {useAuth}  from '../../contexts/Auth';
import './login.css'


export default function Login(){
  const [defaultvalue,setDefault]= useState( reactLocalStorage.get('idconnexion') || {email:"admin@tigmat.io", password:"hdg244DKK53***", prenom:"Admin", nom:"Admin", role:"admin"} )
    const ConexionSucsess= reactLocalStorage.get('idconnexion') || [{email:"admin@tigmat.io", password:"hdg244DKK53***", prenom:"Admin", nom:"Admin", role:"admin"}]
    const data=reactLocalStorage.get('idconnexion')? JSON.parse(ConexionSucsess):ConexionSucsess
    const intialValue= {email:"",password:"",role:""}
    const [Formvalue,SetFormrvalue]= useState(intialValue)
    const [FormErrors, setFormErrors]= useState({});
    const [submit,setSubmit]=useState(false);
    const navigate = useNavigate();
    const auth = useAuth();


    const handleLogin = () =>{
      const role =data.find(dataemail=>dataemail.email===Formvalue.email &&dataemail.password===Formvalue.password)
      auth.login(role) 
    reactLocalStorage.set('user',JSON.stringify(role))
    
      navigate('/Home')
       }
    const handleChange = (e) => {
   
      const {name,value}= e.target;
      SetFormrvalue({...Formvalue, [name]:value})
      console.log(Formvalue)
     
    };

    const handleSubmit = (e) => {
        e.preventDefault();   
        setFormErrors(validate(Formvalue));
        setSubmit(true);
      

     
        
    }
       useEffect(()=>{
        console.log(FormErrors);
           if(Object.keys(FormErrors).length ===0 && submit ){
            console.log(Formvalue+'csa')
            console.log(ConexionSucsess)
           }
           
       },[FormErrors])


    const handleBlur=(e) =>{

    
     
    }

    const validate = (values)=>{
     const errors={}
     const Regex = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
     if(!values.email){
      errors.email="E-mail requis"
    }
    else if(!Regex.test(values.email)){
      errors.email="E-mail non valide"
    }
    if(!values.password){
      errors.password="Mot de passe requis"
    }
    else if(values.password.length<4){
      errors.password="Minimum 4 caractére"
    }
    
    else if (data.find(dataemail=>dataemail.email===Formvalue.email &&dataemail.password===Formvalue.password) ){
    
      errors.connexion="Connexion reussie"
      handleLogin()
    }
    else {
      errors.connexion="Mot de passe ou e-mail incorect"
    }
    return errors;
    };
  
  return (
    <div className='w-full' >
       { Object.keys(FormErrors).length ===0 && submit}
    
  

      <div className=" flexcontainer m-4">
   {console.log()}
        <div className="row justify-content-center align-items-center">
        <div className="col-md-4">
        <h1>Connexion tigmatApp</h1>
        <form onSubmit={handleSubmit} className="">
         <div className="col-auto">
         <p>{FormErrors.connexion}</p>
         
            <label for="email" className="visually-hidden">Email</label>
            <input name="email"  
              className="form-control"
              required 
              type="email"
              placeholder='email' 
              value={Formvalue.email} 
              onChange={handleChange}
              onBlur={handleBlur}
              />
               <p className='text-danger'>{FormErrors.email}</p>
          </div>
          
          <div className="col-auto my-2">
            <label for="password" className="visually-hidden">Mot de passe:</label>
            <input name="password" 
              className="form-control" 
              required
              type='password'
              placeholder='Mot de passe ' 
              value={Formvalue.password} 
              onChange={handleChange}/>
               <p className='text-danger'>{FormErrors.password}</p>
          </div>
         {console.log(data)}
          <div className=" mt-2">
             <button className="btn btn-primary btn-block mb-4">Login</button>
             
          </div>
            
        </form>
        </div>
      </div>
        </div>
     
    </div>
    
  )
}
