import React from 'react'
import { Link } from 'react-router-dom';

export default function Unauthorized(){
  return (
    <div className='container'>
        <div className='row'>
      <h1>Oups.. Vous n'etes pas autorisé conectez vous ou créer un compte</h1>
    
     </div>
    </div>
  )
}
