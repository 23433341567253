import React from 'react';
import RecapCompoment from '../compoment/RecapCompoment';

export default function Recap() {
  return (
    <div><p className='fs-1 text-gray'>Recap Page</p>
        <div><RecapCompoment/></div>
    </div>
  )
}
