import React from 'react'
import { useCardshop } from '../../contexts/Cardshop'
import Cart from '../../compoment/cart/Cart'
import { Link } from 'react-router-dom'
import { useState,useEffect } from 'react'
import { reactLocalStorage } from 'reactjs-localstorage'
import { Tooltip } from 'primereact/tooltip';

export default function Store() {
  const card=useCardshop()
  const [disabled, setDisabled] = useState(true);
  const command= reactLocalStorage.get('userCommand')||null
  useEffect(() => {
   if(command){   if(JSON.parse(command).adress&&JSON.parse(command).billingInfo){
        setDisabled(false)
      }}
   
          return () => {
            setDisabled(true)
          };
        }, []);

  const total= card.cart.reduce((acc,item)=>{
  return acc+=item.total}
  ,0)
  return (
    <>
    <div className="Flexbox-Menu w-full">
      {console.log(card.cart)}
      <div className='flex-col w-full'>
    <Cart cart={card.cart}/>
    <div className='d-flex flex-row-reverse'>
    <p className='mx-4 fs-2'>Total:<span className='fw-bold'>{Math.round(total * 100) / 100}€</span></p>
    </div><div className='d-flex felx-row gap-2'>
    <button className='btn btn-danger' onClick={()=>card.RemoveCard()}> Vider Le panier</button>
    {disabled?( <><Tooltip target=".disabled-button" />
            <span className="disabled-button" data-pr-tooltip="Remplire les info dans profile avant de pouvoir continuer">
         <button className='btn btn-primary' disabled={disabled} title="My Tooltip"> Valider votre achat</button>
         </span> </> 
):(   <Link to="/recap"> <button className='btn btn-primary' disabled={disabled} tooltip="Enter your username"> Valider votre achat</button></Link>
)}
    </div>
    </div>
    <div className="content">
 
    </div>
    </div>
    </>
  )
}
