import { useState } from 'react';
import './App.css';
import { reactLocalStorage } from 'reactjs-localstorage';
import Navbar from './compoment/Navbar';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";   
import { Routes,Route } from 'react-router-dom';
import Layout from './compoment/Layout';
import Loginpage from './pages/Loginpage';
import Signpage from "./pages/Signpage";
import ConnexionPage from './pages/ConnexionPage';
import NotFound from "./NotFound";
import Home from "./pages/home/Home";
import Laptop from "./pages/laptop/Laptop";
import Product from "./pages/product/Product";
import {Profile} from './compoment/Profile'
import Tel from "./pages/tel/Tel";
import Earsphone from "./pages/earsphone/Earsphone"
import HomePC from "./pages/homePC/HomePC";
import Screen from "./pages/screen/Screen";
import Store from "./pages/Store/Store";
import Menu from "./compoment/menus/Menu";
import Unauthorized from './Unauthorized';
import Index from "./pages/dashboard/Index";
import RequireAuth from './compoment/RequireAuth';
import Recap from './pages/Recap';


function App() {
 
  return (
    <Routes>
       <Route path='/' element={<Layout/>}>

       <Route path='/login' element={<Loginpage/>} />
       <Route path='/Signin' element={<Signpage/>} />
       <Route path='/' element={<ConnexionPage/>} />
       <Route path='/Home' element={<Home/>} />    
       <Route path='/unauthorized' element={<Unauthorized/>} />   
       <Route path='/Laptop' element={<Laptop/>} />
       <Route path='/Product/:Typeproduct/:productId' element={<Product/>} />
       <Route path='/Tel' element={<Tel/>} />
       <Route path='/Ears' element={<Earsphone/>} />    
       <Route path='/PCHome' element={<HomePC/>} />
       <Route path='/screen' element={<Screen/>} />      
       <Route path='/Store' element={<Store/>} />  
      {/* ROUTE admin*/}
        <Route element={<RequireAuth roles={["admin"]}/>}>
     
       <Route path='/Dashboard' element={<Index/>} /> 
     
  
       </Route>
         {/* ROUTE user*/}

       <Route element={<RequireAuth roles={["user","admin"]}/>}>
       <Route path='/Profile' element={<Profile/>} />    
       <Route path='/recap' element={<Recap/>} /> 
       </Route>

       <Route path='*' element={<NotFound/>} />
       </Route>
     
    </Routes>
   
  );
}

export default App;
