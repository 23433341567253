import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useCardshop } from "../contexts/Cardshop";
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import {useEffect,useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";

const RecapCompoment = () =>{
    const cart=useCardshop().cart
const [adress, setAdress] = useState("");
const [billing, setBilling] = useState("");

    useEffect(() => {
      
setAdress(JSON.parse(reactLocalStorage.get('userCommand')).adress.adress)
setBilling(JSON.parse(reactLocalStorage.get('userCommand')).billingInfo.number)
      return () => {
        setAdress("")
      };
    }, []);
 
return(

   <>
   
     <div>
     {console.log(adress)}
           
                <div className="card" >
                <DataTable value={cart} responsiveLayout="scroll">
                    <Column field="name" header="nom du produit :"></Column>
                    <Column field="qty" header="quantité :"></Column>
                    <Column field="price" header="Prix :"></Column>
                </DataTable>
            </div>
           <div >
          <ul className="flex flex-column">
            <li className="d-flex flex-row gap-2 align-items-center"><p>Adress :</p><p>{adress}</p> <Link to="/profile"><Button>Modifier</Button></Link> </li>
            <li className="d-flex flex-row gap-2 align-items-center"><p>Billing :</p><p>Pay with card ************{billing.substr(-3)}</p>  <Link to="/profile"><Button>Modifier</Button></Link> </li>

          </ul>
           </div>
       

        </div>
   </>
)
}


export default RecapCompoment;