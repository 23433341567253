import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import { reactLocalStorage } from "reactjs-localstorage";

import React from 'react'

const RequireAuth =({roles = [] })=> {
  const auth = useAuth();
  const location = useLocation();
      return( !roles.length || roles.includes(auth?.user.role)
        ?
        <Outlet/>:
        auth?.user
            ?<Navigate to="/unauthorized" state={{from:location}} replace/>
            
            :<Navigate to="/login" state={{from:location}} replace/>
      )
}

export default RequireAuth;
