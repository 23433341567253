import {React} from 'react'
import "./Productdetails.css"
import { Link } from 'react-router-dom'
import {useCardshop} from '../../contexts/Cardshop'
import { useState } from 'react'
import { useDataContext } from '../../contexts/DataContext'
import { useAuth } from '../../contexts/Auth'

export default function Productdetails({data,Setcart,cart,Typeproduct,id}) {
   const cardshop = useCardshop();
   const auth=useAuth()
   const Disabled= auth?.user.role==="admin"?false:true
   const ProductData =useDataContext().dataJson; 
   const initialeDetails=ProductData[Typeproduct][id].characteristic[0]
   const [detailsData,setDetailsData] = useState({screenDisplay:initialeDetails.screenDisplay,screenResolution:initialeDetails.screenResolution,
    processor:initialeDetails.processor,
    ram:initialeDetails.ram,
    memory:initialeDetails.memory,
    hardDrive: initialeDetails.hardDrive,
    graphic: initialeDetails.graphic,
    chipset: initialeDetails.chipset
    ,battery:initialeDetails.battery,})
   const dataContext= useDataContext()
   const handleChange =(e)=> {
    console.log(e.target.name)
    setDetailsData({...detailsData,[e.target.name]:e.target.value})
   }
   if (!ProductData) return "(<><p>Loading...</p> </>)"

  return (

    <div className="container p-5">
          

      <div className='row justify-content-around align-items-center'>
    
    
        <div className="col-md-5">
          <div className='card'>
        <img src={data.image} className='card-img-top w-auto' alt='detail product'/>
        <div class="card-body bg-light">
    <h5 class="card-title">{data.name}</h5>
    <p class="card-text fs-4 text-success fw-bold">{data.Price}€</p>
  </div>
        </div>
        </div>
      
        <div className="col-md-4">
        <p className="titre destription">Caractéristique:</p>
        {console.log(ProductData[Typeproduct][id].characteristic)}
        <dl>
            <dt>Standing screen display size :</dt>
            <dd><p className='sss'><select className='form-select' name="screenDisplay" value={detailsData.screenDisplay} onChange={handleChange} disabled={Disabled}> inches
            <option value={"13.3"}>13.3</option>
            <option value={"14"}>14</option>
            <option value={"15.6"}>15.6</option>
            <option value={"17"}>17</option>
            </select>Inches</p></dd>
            <dt>Max Screen Resolution </dt>
            <dd><p><select className='form-select'  name="screenResolution" value={detailsData.screenResolution} onChange={handleChange} disabled={Disabled}> 
            <option value={" 1920x1080"}> 1920x1080</option>
            <option value={"800x600"}>800x600</option>
          
            </select> pixel</p></dd>
            <dt>Processor </dt>
            <dd><p><select className='form-select' name="processor" value={detailsData.processor} onChange={handleChange} disabled={Disabled}> 
            <option value={"Intel Quad-Core i5-1135G7"}>Intel Quad-Core i5-1135G7</option>
            <option value={"Intel Core i7-12700H "}>Intel Core i7-12700H </option>
            <option value={"AMD Ryzen 7 5825U"}>AMD Ryzen 9 6900HX </option>
            <option value={"1.3 GHz apple_ci7"}>1.3 GHz apple_ci7</option>
            </select></p></dd>
            <dt>RAM </dt>
            <dd><p><select name="ram" className='form-select' value={detailsData.ram} onChange={handleChange} disabled={Disabled}> 
            <option value={"8"}>8</option>
            <option value={"16"}>16</option>
            <option value={"32"}>32</option>
            <option value={"64"}>64</option>
            </select> GB DDR4</p></dd>
            <dt> Memory Speed </dt>
            <dd><p><select name="memory" className='form-select' value={detailsData.memory} onChange={handleChange} disabled={Disabled}> 
            <option value={"1200"}>1200</option>
            <option value={"2400"}>2400</option>
            <option value={"4800"}>4800</option>
            </select>MHz</p></dd>
            <dt> Hard Drive </dt>
            <dd><p><select name="hardDrive" className='form-select' value={detailsData.hardDrive} onChange={handleChange} disabled={Disabled} > 
            <option value={" 256 GB"}>256 GB</option>
            <option value={"512 GB"}>512 GB</option>
            <option value={" 1 To "}> 1 To </option>
            </select> SSD</p></dd>  
            <dt>Graphics Coprocessor</dt>
            <dd><p><select name="graphic" className='form-select' value={detailsData.graphic} onChange={handleChange} disabled={Disabled}> 
            <option value={"Intel Iris Plus"}>Intel Iris Plus</option>
            <option value={"AMD Radeon Pro"}>AMD Radeon Pro</option>
            <option value={"Nvidia GeForce MX Series"}>Nvidia GeForce MX Series</option>
            </select></p></dd>
            <dt>Chipset Brand</dt>
            <dd><p><select name="chipset" className='form-select' value={detailsData.chipset} onChange={handleChange} disabled={Disabled}> 
            <option value={"Intel "}>Intel </option>
            <option value={"AMD"}>AMD</option>
         
            </select></p></dd>
            <dt>Average Battery Life 20 Hours </dt>
            <dd><input type="text" name="battery" value={detailsData.battery} className="form-control" onChange={handleChange} disabled={Disabled}/></dd>
            </dl>
            <div>
              

        <Link to='/Laptop'><button className="btn btn-dark btn-block ">Cancel</button></Link>
        &nbsp;
        {auth?.user.role==="admin"?(<button onClick={()=> dataContext.addProductDetails(Typeproduct,data.id,detailsData)} className="btn btn-primary btn-block ">Save </button>
       ):(<button onClick={()=>cardshop.AddtoCarts(data.name,data.Price,data.image)} className="btn btn-primary btn-block ">Add to cart</button>
)}

    
        </div>
        </div>
        </div>
        </div>

  )
}
