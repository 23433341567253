import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import { TabView, TabPanel } from 'primereact/tabview';
import { useState ,useEffect} from "react";
import {reactLocalStorage} from 'reactjs-localstorage';
import { useCardshop } from "../contexts/Cardshop";


export const Profile = () => {
    const card=useCardshop()
    const auth = useAuth()
    const navigate = useNavigate()

    const handleLogout = () => {
        auth.logout()
        navigate("/")
    }
    const command= reactLocalStorage.get('userCommand')||null

    useEffect(() => {
      if(command){
      setAdressForm(JSON.parse(command).adress)
      setBillingForm(JSON.parse(command).billingInfo)
    }
            return () => {
              setAdressForm({})
              setBillingForm({})
            };
          }, []);
    
const [adressForm, setAdressForm] = useState({});
const [billingForm, setBillingForm] = useState({});

const handleChange=(e)=>{
 const {name,value}= e.target
 setAdressForm({...adressForm,[name]:value})
}
const handleChangeBill=(e)=>{
    const {name,value}= e.target
    setBillingForm({...billingForm,[name]:value})
   }

   const handleSubmit = (e) => {
    e.preventDefault();   
    reactLocalStorage.set('userCommand',JSON.stringify({billingInfo:{...billingForm},adress:{...adressForm},command:{...card.cart}}))
   }

 
    return(
        <div>
           <p> Welcome {auth.user?.email}</p>
            <TabView>
    <TabPanel header="Address">
    <div className="d-flex flex-column gap-2">
    <label for="adress" className="">Street:</label>
            <input name="adress"  
              className="form-control"
              required 
              type="text"
              placeholder='adress' 
              value={adressForm.adress} 
              onChange={handleChange}
              onBlur={"handleBlur"}
              />
               <label for="adress" className="">Postale code:</label>
            <input name="postalcode"  
              className="form-control"
              required 
              type="text"
              placeholder='Postal code' 
              value={adressForm.postalcode} 
              onChange={handleChange}
              onBlur={"handleBlur"}
              />
               <label for="adress" className="">city:</label>
               {console.log(adressForm)}
            <input name="city"  
              className="form-control"
              required 
              type="text"
              placeholder='city' 
              value={adressForm.city} 
              onChange={handleChange}
              onBlur={"handleBlur"}
              />
            <button className="btn btn-primary" onClick={handleSubmit}>Save</button>
                            </div>
    </TabPanel>
{    //-----      -----------------------------------BIILING---------------------     
}    
    <TabPanel header="Credit cart">
      <div className="d-flex flex-column gap-2"> 
         <div className="">
         <label for="name" className="">Name:</label>
            <input name="name"  
              className="form-control"
              required 
              type="text"
              placeholder='Nom' 
              value={billingForm.name} 
              onChange={handleChangeBill}
              onBlur={"handleBlur"}
              />
              </div>
              <div>
               <label for="number" className="">N°:</label>
            <input name="number"  
              className="form-control"
              required 
              type="text"
              placeholder='Numéro de carte' 
              value={billingForm.number} 
              onChange={handleChangeBill}
              onBlur={"handleBlur"}
              />
              </div>
              <div className="d-flex flex-row gap-3">
              <div>
               <label for="adress" className="">exp:</label>
            <input name="adress"  
              className="form-control"
              required 
              type="text"
              placeholder='07/23' 
              value={billingForm.expiration} 
              onChange={handleChangeBill}
              onBlur={"handleBlur"}
              />
              </div>
              <div>
               <label for="code" className="">cvv:</label>
               {console.log(billingForm)}
            <input name="code"  
              className="form-control"
              required 
              type="text"
              placeholder='743' 
              value={billingForm.code} 
              onChange={handleChangeBill}
              onBlur={"handleBlur"}
              />
              </div>
              </div>
              <div>
              </div>
              <button className="btn btn-primary my-2" onClick={handleSubmit}>Save</button>

              </div>
    </TabPanel>
    
</TabView>
<button className="btn btn-primary" onClick={handleLogout}>Logout</button>

        </div>
    )
}