import React from 'react'
import "./Menus.css"
import { Laptop,House,List,Phone,Earbuds,Tv,PcDisplay } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
export default function Menu() {
  const auth = useAuth();
  return (
    <div className="leftbar">
   
  <div className="leftbarWrapper">
     <div className="leftbarMenu">
            <ul className="leftbarList w-full">
            <Link to ="/Home"><li className="leftbarListItem fs-5 "><House/>Home</li></Link> 
           
             <Link to ="/Laptop">  <li className="leftbarListItem fs-5 w-full"><Laptop/>Laptop</li></Link>
             <Link to ="/Tel">  <li className="leftbarListItem fs-5"><Phone/>Smartphone</li></Link>    
             <Link to ="/ears">  <li className="leftbarListItem fs-5"><Earbuds/> ears</li></Link>  
             <Link to ="/PCHome">  <li className="leftbarListItem fs-5"><PcDisplay/>Home PC</li></Link>  
             <Link to ="/screen">  <li className="leftbarListItem fs-5"><Tv/>Screen</li></Link>  
        
           
             
            </ul>
     </div>
 </div>
</div>  
  )
}
