import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter,Routes, Route} from 'react-router-dom';
import { AuthProvider } from './contexts/Auth';
import { CardshopProvider } from './contexts/Cardshop';
import { DataProvider } from './contexts/DataContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <AuthProvider>
      <DataProvider>
  <CardshopProvider>
      <Routes>
      <Route path='/*' element={<App />}/> 
    </Routes>
    </CardshopProvider>
    </DataProvider>
    </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))

