import React from 'react'
import { useAuth } from '../contexts/Auth'
import { useState } from 'react'
import { useDataContext } from '../contexts/DataContext'

export default function ButtonAddProduct({data,setData,index}) {
const auth=useAuth()
const datacontext= useDataContext()
const test =datacontext.dataJson

const i =Object.entries(test).map((x)=>{return x})    
const [formProduct, setFormProduct] = useState({
  id: parseInt(data.length),
  Product: "Laptopdata",
  name: "",
  image: "",
  Price: "",
  characteristic: [
    {
      screenResolution: "1920x1080",
      processor: "screen1",
      screenDisplay: "13.3",
      hardDrive: "1 To SSD",
      graphic: "AMD Radeon Pro",
      memory: "4800",
      ram: "32"
    }
  ]
})
const [formSent, setFormSent] = useState(false)
const handleSubmit =(e)=>{
  e.preventDefault()
 datacontext.addProduct(index,formProduct)
 setFormSent(false)

}

const handleChange =(e)=>{
    const {name,value}= e.target
    setFormProduct({...formProduct,[name]:value})
}
 return !formSent&&auth?.user.role==="admin"?(
    <div className='row mx-2'>
     <div class="col">

            <button className='btn btn-primary' onClick={()=>setFormSent(true)}>+ Add product</button>
            </div>
       
    </div>
  ):formSent&&auth?.user.role==="admin"?(
  
      <div className='row mx-2'>
      
      <form onSubmit={handleSubmit} className="row row-cols-lg-auto g-3 w-90" >
      {console.log()}
          {console.log(...data)}
          <div class="col">
          <input name="name" type="text" placeholder='Product name' className='form-control' onChange={handleChange} required/>
          </div>
            <div class="col">
          <input name="Price" type="text" placeholder='Price' className='form-control' onChange={handleChange} required/>
          </div>
          <div class="col">
          <input name="image" type="text" placeholder='Url photo' className='form-control' onChange={handleChange} required/>
          </div>
          <div class="col">
          <button className='btn btn-primary'>Add product</button>
          </div>
      </form>
  </div>
  ):("")
  
}
