import {React,useState} from 'react'
import Menu from '../../compoment/menus/Menu'
import Data from '../../data/data.json'
import Cart from '../../compoment/cart/Cart'
import { useParams} from 'react-router-dom'
import Productdetails from '../../compoment/productdetails/Productdetails'
import {useCardshop} from '../../contexts/Cardshop'
import { useDataContext } from '../../contexts/DataContext'
export default function Product() {
  const {Typeproduct,productId} = useParams();
  const [cart, Setcart] = useState([])
  const ProductData =useDataContext().dataJson; 
  
  const id =parseInt(productId, 10)

  return (
    <div className='w-75 '>
    <div className="Flexbox-Menu">
   
    <div className="d-flex flex-row">
   
  
    <Productdetails data={ProductData[Typeproduct][id]} cart={cart} Setcart={Setcart} Typeproduct={Typeproduct} id={id} />
   
 
 
    </div>
    </div>
    </div>
  )
}
