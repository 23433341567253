import {React,useState,} from 'react'
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import "/node_modules/primeflex/primeflex.css"
import "./cart.css"
import { Rating } from 'primereact/rating';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { useCardshop } from '../../contexts/Cardshop';

        

export default function Cart({cart}) {  
  const cardShop = useCardshop()
  const Dataproduct= cart
  const [layout, setLayout] = useState('grid');

  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortField, setSortField] = useState(null);
  const sortOptions = [
    {label: 'Price High to Low', value: '!Price'},
    {label: 'Price Low to High', value: 'Price'},
]; 
  
const onSortChange = (event) => {
  const value = event.value;

  if (value.indexOf('!') === 0) {
      setSortOrder(-1);
      setSortField(value.substring(1, value.length));
      setSortKey(value);
  }
  else {
      setSortOrder(1);
      setSortField(value);
      setSortKey(value);
  }
}

const renderListItem = (data) => {
  return (
      <div className="col-12">{console.log(data)}
          <div className="product-list-item d-flex flex-row align-items-center">
              <img src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
              <button className='btn btn-danger'  onClick={()=>cardShop.removeProduct(data)} >-</button>
              <span>Qté :{data.qty}</span>
              <i className="pi pi-tag product-category-icon"></i>
              <button className='btn btn-success'  onClick={()=>cardShop.AddOneProduct(data)} > +</button>

              <div className="product-list-detail">
            
                  <div className="product-name">{data.name}</div>
              
              
                 <span className="product-category">{data.category}</span>
              </div>
              <div className="product-list-action">
                  <span className="product-price">€{data.price}</span>

              </div>
              <div className='mt-5'>
                {console.log((data))}
              <p className='fs-5 mt-5'>sous-total:<span className="mt-3 product-price">{Math.round(data.total * 100) / 100}€</span></p>
              </div>
          </div>
      </div>
  );
}
const itemTemplate = (Dataproduct, layout) => {
  if (!Dataproduct) {
      return;
  }

  if (layout === 'list')
      return renderListItem(Dataproduct);
  else if (layout === 'grid')
      return renderListItem(Dataproduct);
}

const renderHeader = () => {
  return (
      <div className="grid grid-nogutter">
          <div className="col-6" style={{textAlign: 'left'}}>

          </div>
          
      </div>
  );
}

const header = renderHeader();
 
  
  return(
    <div className='container'>
      <div className="row justify-content-center">
        <h2>Card</h2>
    <div>
      {console.log(cart.length>0)}
    <div className="dataview-demo">
    {console.log(Dataproduct)}
      <div className="card">
        {cart.length>0 ?
        (<DataView value={Dataproduct} layout={layout} header={header}
                  itemTemplate={itemTemplate} paginator  rows={9}
                  sortOrder={sortOrder} sortField={sortField}
                />
                
                 ):
                (<p>Panier est vide</p>)} 
          
      </div>
  </div>
     
 
</div>
</div>
</div>
  )
}
