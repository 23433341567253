import { React,useState, useEffect} from 'react'
import CountrySelect from './CountrySelect'
import {reactLocalStorage} from 'reactjs-localstorage';
import {useNavigate} from 'react-router-dom'




export default function Signin(){
   const defaul={email:"admin@tigmat.io", password:"hdg244DKK53***",  role:"admin"}
    const intialValue= {email:"",password:""}
    const [Formvalue,SetFormrvalue]= useState(intialValue)
    const [FormErrors, setFormErrors]= useState({});
    const [submit,setSubmit]=useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
     
      const {name,value}= e.target;
      SetFormrvalue({...Formvalue, [name]:value})
      console.log(Formvalue)
     
    };

    const handleSubmit = (e) => {
        e.preventDefault();   
        setFormErrors(validate(Formvalue));
        setSubmit(true);
        const data = {email:Formvalue.email,password:Formvalue.password,role:"user"};
        const oldData=reactLocalStorage.get('idconnexion')?JSON.parse(reactLocalStorage.get('idconnexion')) :[{email:"admin@tigmat.io", password:"hdg244DKK53***",role:"admin"}];
        console.log(oldData)
        reactLocalStorage.set('idconnexion',JSON.stringify([...oldData,data]))
       
        
    }
       useEffect(()=>{
        
           if(Object.keys(FormErrors).length ===0 && submit ){
            navigate('/Login')
           }
           
           
          
       },[FormErrors])


    const handleBlur=(e) =>{

    
     
    }

    const validate = (values)=>{
     const errors={}
     const Regex = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
     if(!values.email){
      errors.email="E-mail requis"
    }
    else if(!Regex.test(values.email)){
      errors.email="E-mail non valide"
    }
    if(!values.password){
      errors.password="Mot de passe requis"
    }
    else if(values.password.length<4){
      errors.password="Minimum 4 caractére"
    }
    else if(values.password.length>10){
      errors.password="Mot de passe trop"
    }
    else if(values.Firstname.length<1){
      errors.Firstname="entrer votre prénom"
    }
    else if(values.Lastname.length<1){
      errors.Lastname="entrer votre nom"
    }
    
    return errors;
    };
  
  return (
    <div className='w-full'>
     {console.log(reactLocalStorage.get('idconnexion'))}
     
      <div className="container">
        <div className="row justify-content-center align-items-center m-5">
        <div className="col-md-4">
        <h1>Inscription tigmatApp</h1>
        <form onSubmit={handleSubmit} className="">
         <div className="col-auto">
         <p>{FormErrors.connexion}</p>
         
            <label for="email" className="visually-hidden">Email</label>
            <input name="email"  
              className="form-control" 
              type="email"
              placeholder='email' 
              value={Formvalue.email} 
              onChange={handleChange}
              onBlur={handleBlur}
              />
               <p className='text-danger'>{FormErrors.email}</p>
          </div>
          
          <div className="col-auto my-2">
            <label for="password" className="visually-hidden">Mot de passe:</label>
            <input name="password" 
              className="form-control" 
              type='password'
              placeholder='Mot de passe ' 
              value={Formvalue.password} 
              onChange={handleChange}/>
               <p className='text-danger'>{FormErrors.password}</p>
          </div>
          <div className="col-auto my-2">
            <label for="Firstname" className="visually-hidden">preNom</label>
            <input name="Firstname" 
              className="form-control" 
              type='text'
              placeholder='prenom' 
              value={Formvalue.Firstname} 
              onChange={handleChange}/>
               <p className='text-danger'>{FormErrors.Firstname}</p>
          </div>
          <div className="col-auto my-2">
            <label for="Lastname" className="visually-hidden">Nom</label>
            <input name="Lastname" 
              className="form-control" 
              type='text'
              placeholder='Nom' 
              value={Formvalue.Lastname} 
              onChange={handleChange}/>
               <p className='text-danger'>{FormErrors.Lastname}</p>
          </div>
        
          <div className="col-auto my-2">
          <CountrySelect/>
          </div>
          <div className=" mt-2">
             <button className="btn btn-primary btn-block mb-4">Inscription</button>
             
             
          </div>
            
        </form>
        </div>
      </div>

        </div>
     
    </div>
    
  )
}
