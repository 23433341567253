import { React,createContext,useContext,useState,useEffect } from "react";
import Data from '../data/data.json';
import { reactLocalStorage } from "reactjs-localstorage";


const DataContext = createContext()
export const DataProvider=({children}) => {
   const DataProduct=Object.values(Data)
   const [dataJson,setDataJson]=useState(DataProduct)

     const  addProduct = (index,newProduct)=>{
    setDataJson([...dataJson.map((data,x)=>x===parseInt(index)?[...data,newProduct]:data)]);
    reactLocalStorage.set('dataProduct',JSON.stringify([...dataJson.map((data,x)=>x===parseInt(index)?[...data,newProduct]:data)]))
    
 }
 const addProductDetails =(Typeproduct,id,arrayDetails)=>{
   
    setDataJson([...dataJson.map((dataProduct,x)=>x===parseInt(Typeproduct)?
        dataProduct.map((dataProductItem,x)=>dataProductItem.id===id?
        {...dataProductItem,characteristic:[arrayDetails]}:dataProductItem):dataProduct)])
 }

 useEffect(()=>{
   if (reactLocalStorage.get('dataProduct')){
    setDataJson(JSON.parse(reactLocalStorage.get('dataProduct')))
   }
 },[dataJson])


    return(
<DataContext.Provider value={{dataJson,addProduct,addProductDetails}}>
{children}
</DataContext.Provider>)

}


export const useDataContext = () =>{
    return (useContext(DataContext))
} 
