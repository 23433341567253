import React from 'react'
import { Link } from 'react-router-dom';

export default function NotFound(){
  return (
    <>
      <h1>Oups.. Votre page n'existe pas</h1>
     <Link to="/"> <button className="btn btn-danger btn-block mb-4">Retour</button></Link>

    </>
  )
}
