import {React,createContext,useState,useContext,useEffect} from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
const AuthContext = createContext(null)


export const AuthProvider = ({children}) => {

  const [user,setUser] = useState({})

  const login = user => {
    setUser(user)
   

  }
  const logout = user => {
    setUser({})
    reactLocalStorage.remove('user');
  }

  useEffect(()=>{
     if (reactLocalStorage.get('user')){
      setUser(JSON.parse(reactLocalStorage.get('user')))
     }
    },[])
  
  return (<AuthContext.Provider value={{user, login, logout}}>{children}</AuthContext.Provider>)
}

export  const useAuth = () => {
   return (useContext(AuthContext))
}


