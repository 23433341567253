import { Outlet } from "react-router-dom"
import Navbar from "./Navbar"
import Menu from "./menus/Menu"

const Layout=()=>{
    return(
        <>
        <Navbar/>

        <div className="d-flex flex-row">
        <Menu/>
        <Outlet/>
        </div>
        </>
    )
}

export default Layout